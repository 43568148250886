@import "./variables";
@import "./bootstrap/bootstrap";
@import "./utilities";

html {
  font-size: 12px;
  scroll-behavior: smooth;
  padding: env(safe-area-inset);
}
.main-logo {
  max-height: 50px;
}
.main-logo-lg {
  max-width: 250px;
}

.main-title {
  font-size: 45px;
  color: $white;
}
@media (min-width: map-get($grid-breakpoints, "md")) {
  .main-title {
    // font-weight: 600;
    // color: $white;
    font-size: 60px;
    // -webkit-text-fill: $white;
    // -webkit-text-stroke: 3px $primary;
  }
}

.main-subtitle {
  color: $white;
}

.another-title {
  font-size: 45px;
}

.text-small {
  font-size: 12px;
}

.bg-dark.extra-dark {
  background-color: #000 !important;
}
.footer {
  background-color: #EADCC7;
  a {
    font-size: 1.2em;
    @extend .text-dark;
    &:hover {
      color: darken($gray-500, 20%) !important;
    }
  }
}
.close-button-position {
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  align-items: center;
  display: flex;
  .close {
    color: $gray-600 !important;
    font-weight: 500;
    &:hover {
      @extend .text-white;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, "md")) {
  .close-button-position {
    background-color: transparent;
    border-color: transparent;
    .close {
      color: $gray-500 !important;
    }
  }
}

.modal-dialog {
  pointer-events: visible;
}
.modal-backdrop {
  &.show {
    opacity: 0.8;
  }
}

.border-2 {
  border-width: 2px;
}
.border-3 {
  border-width: 3px;
}

.lazy-load {
  object-fit: cover;
  object-position: center;
}
.object-cover {
  object-fit: cover;
  object-position: center;
}
.project-image {
  width: 100%;
  height: 300px !important;
  max-height: 300px !important;
  overflow-y: hidden;
}
.project-template {
  display: none;
}
.project-item-template {
  display: none;
}

@media (min-width: map-get($grid-breakpoints, "md")) {
  .project-image {
    width: 100%;
    min-height: 300px !important;
    max-height: 300px !important;
    overflow-y: hidden;
  }
}

.back-to-top {
	display: none;
	width: 60px;
	height: 60px;
  position: fixed;
  justify-content: center;
  align-items: center;
	z-index: 999;
	right: 20px;
  bottom: 20px;
  color: $primary;
  background-color: $white;
  border: 1px solid $primary;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 2rem;
}
.chat-with-us {
  display: none;
  // position: fixed;
  // right: 10px;
  // top: 45%;
  // li {
  //   @extend .mb-3;
  // }
}
.nav-alternating, .nav-alternating-thumb {
  li {
    align-items: center;
    margin-bottom: 8px;
    &:before {
      content: '\f005';
      font-weight: 900;
      font-family: "Font Awesome 5 Free";
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-size: 22px;
      vertical-align: middle;
      align-items: center;
      margin-right: 5px;
    }
  }
  li:nth-child(odd) {
    // color: $primary;
  }
}

.nav-alternating-thumb {
  li {
    &:before {
      font-weight: 400;
      content: '\f164'
    }
  }
}

@media (min-width: map-get($grid-breakpoints, "md")) {
  .nav-alternating-thumb {
    li:last-child {
      // color: $primary;
    }
  }
}

#main-menu {
  background-color: rgba(255, 255, 255, 0.333);
  .navbar-toggler {
    color: #444;
    border: 1px solid #444;
  }
  .navbar-brand {
    .brand-text {
      font-size: 0.8rem;
      font-style: italic;
    }
  }
  .nav-link {
    border: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid #444;
    }
  }
  &:hover, &.active {
    background-color: #fff;
    .navbar-brand, .nav-link {
      color: #444;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, "md")) {
  #main-menu {
    background-color: rgba(255, 255, 255, 0.0);
    .navbar-toggler {
      color: #444;
      border: 1px solid #444;
    }
    .navbar-brand {
      .brand-text {
        font-size: 0.8rem;
        font-style: italic;
      }
    }
    .nav-link {
      border: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid #444;
      }
    }
    &:hover, &.active {
      background-color: #fff;
      .navbar-brand, .nav-link {
        color: #444;
      }
    }
  }
}

#mainCarousel {
  .carousel-item {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .number {
      text-transform: uppercase;
      transition: none 0s ease 0s;
      text-align: left;
      border-width: 0px;
      padding: 0px;
      letter-spacing: 0px;
      font-weight: 900;
      font-size: 180px;
      line-height: 1.2;
      opacity: 0.5;
      color: #fff;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: rgba(255,255,255,0.5);
      animation-delay: -0.5s;
    }
    .subtitle {
      font-weight: 500;
      animation-delay: 1.0s;
      display: inline-block;
      position: relative;
      color: #ccde02;
    }
  }
}

#photoCarousel {
  .carousel-item {
    height: 95vh;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    img {
      max-height: 90vh;
    }

    .photo-stripe {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(0,0,0,0.4);
      color: #bfbfbf;
      text-align: right;
      font-size: 1.2em;
      padding-right: 10px;
    }
  }
  .carousel-control {
    i {
      @extend .shadow;
    }
  }
}

.fa-fs {
  font-size: 0.7em !important;
}