$arial-font-family: 'Arial-Lgt';
$arial-font-path: "https://s3-ap-northeast-1.amazonaws.com/ose.com/fonts";

@font-face {
  font-family: 'Arial';
  src:  url('#{$arial-font-path}/#{$arial-font-family}.eot?#iefix');
  src:
  url('#{$arial-font-path}/#{$arial-font-family}.eot') format('embedded-opentype'),
    url('#{$arial-font-path}/#{$arial-font-family}.woff2') format('woff2'),
    url('#{$arial-font-path}/#{$arial-font-family}.ttf') format('truetype'),
    url('#{$arial-font-path}/#{$arial-font-family}.woff') format('woff'),
    url('#{$arial-font-path}/#{$arial-font-family}.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

$variable-prefix:             bs- !default;
$font-family-sans-serif:      Arial, Helvetica, sans-serif !default;
// stylelint-enable value-keyword-case
$font-family-base:            var(--bs-font-sans-serif) !default;

:root {
  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$variable-prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
}
