@import "./bootstrap/variables";

// Type display classes
@each $display, $font-size in $display-font-sizes {
  .display-#{$display} {
    @include font-size($font-size);
    font-family: $font-family-sans-serif;
    font-weight: $display-font-weight;
    line-height: $display-line-height;
  }
}

@each $breakpoint, $breakpointSize in $grid-breakpoints {
  @media (min-width: $breakpointSize) {
    @each $display, $font-size in $display-font-sizes {
      .display-#{$breakpoint}-#{$display} {
        @include font-size($font-size);
        font-family: $font-family-sans-serif;
        font-weight: $display-font-weight;
        line-height: $display-line-height;
      }
    }
  }
}

.fs-18px {
  font-size: 1.375rem;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

$spacingSizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
@each $breakpoint, $breakpointSize in $grid-breakpoints {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    @each $spaceSize in $spacingSizes {
      .mt-#{$breakpoint}-#{$spaceSize}em {
        margin-top: #{$spaceSize}rem;
      }
      .mx-#{$breakpoint}-#{$spaceSize}em {
        margin-left: #{$spaceSize}rem;
        margin-right: #{$spaceSize}rem;
      }
      .my-#{$breakpoint}-#{$spaceSize}em {
        margin-top: #{$spaceSize}rem;
        margin-bottom: #{$spaceSize}rem;
      }
      .m-#{$breakpoint}-#{$spaceSize}em {
        margin-top: #{$spaceSize}rem;
        margin-bottom: #{$spaceSize}rem;
        margin-left: #{$spaceSize}rem;
        margin-right: #{$spaceSize}rem;
      }

      .px-#{$breakpoint}-#{$spaceSize}em {
        padding-left: #{$spaceSize}rem;
        padding-right: #{$spaceSize}rem;
      }
      .py-#{$breakpoint}-#{$spaceSize}em {
        padding-top: #{$spaceSize}rem;
        padding-bottom: #{$spaceSize}rem;
      }
      .p-#{$breakpoint}-#{$spaceSize}em {
        padding-top: #{$spaceSize}rem;
        padding-bottom: #{$spaceSize}rem;
        padding-left: #{$spaceSize}rem;
        padding-right: #{$spaceSize}rem;
      }
    }
  }
}

@each $spaceSize in $spacingSizes {
  .mt-#{$spaceSize}em {
    margin-top: #{$spaceSize}rem;
  }
  .mx-#{$spaceSize}em {
    margin-left: #{$spaceSize}rem;
    margin-right: #{$spaceSize}rem;
  }
  .my-#{$spaceSize}em {
    margin-top: #{$spaceSize}rem;
    margin-bottom: #{$spaceSize}rem;
  }
  .m-#{$spaceSize}em {
    margin-top: #{$spaceSize}rem;
    margin-bottom: #{$spaceSize}rem;
    margin-left: #{$spaceSize}rem;
    margin-right: #{$spaceSize}rem;
  }

  .px-#{$spaceSize}em {
    padding-left: #{$spaceSize}rem;
    padding-right: #{$spaceSize}rem;
  }
  .py-#{$spaceSize}em {
    padding-top: #{$spaceSize}rem;
    padding-bottom: #{$spaceSize}rem;
  }
  .p-#{$spaceSize}em {
    padding-top: #{$spaceSize}rem;
    padding-bottom: #{$spaceSize}rem;
    padding-left: #{$spaceSize}rem;
    padding-right: #{$spaceSize}rem;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.hover-shadow {
  &:hover {
    img {
      @extend .shadow;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, "md")) {
  .position-md-absolute {
    position: absolute;
  }
}
@each $key, $size in $grid-breakpoints {
  @media (min-width: $size) {
    .row-#{$key}-overflow {
      overflow-x: scroll;
    }
    .row-#{$key}-overflow-none {
      overflow-x: hidden !important;
    }
  }
}

.row-no-wrap {
  overflow-x: scroll;
  white-space: nowrap;
  &.scrolling-wrapper {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.vh-100 {
  height: 100vh;
}

.rounded-extra {
  border-radius: 8px;
}

.maxh-50vh {
  max-height: 50vh !important;
}